import type { ComponentProps } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';

import type { DynamicIconPropIcon } from '../../assets/DynamicIcon/DynamicIcon';
import type { GutterProp } from '../../common/sizing';
import type {
  BreadcrumbProps,
  BreadcrumbPropType,
  BreadcrumbsPropHome,
} from '../../controls/Breadcrumbs/Breadcrumbs';
import type { StackSpacing } from '../../utilities/Stack/Stack';
import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import type { ShellPropLayoutMode, ShellPropSize } from './utils';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import { backgrounds } from '../../common/backgrounds';
import { sizing } from '../../common/sizing';
import { Badge } from '../../components/Badge/Badge';
import { BadgeGroup } from '../../components/Badge/BadgeGroup';
import { Tabs } from '../../components/Tabs/Tabs';
import { Breadcrumbs } from '../../controls/Breadcrumbs/Breadcrumbs';
import { CloseButton } from '../../controls/CloseButton/CloseButton';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Large } from '../../text/Large';
import { Text } from '../../text/Text';
import { space } from '../../utilities/shared/sizes';
import { VStack } from '../../utilities/Stack/VStack';
import {
  HorizontalScrollShadow,
  useHorizontalShadowOnScroll,
  useVerticalShadowOnScroll,
  VerticalScrollShadow,
} from '../../utilities/useShadowOnScroll';
import { useViewport } from '../../utilities/useViewport';
import {
  ShellLayoutModeProvider,
  ShellSizeProvider,
  useShellLayoutMode,
  useShellSize,
} from './utils';

const ShellHRule = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '0',

  '&:before': {
    content: '',
    display: 'block',
    width: '100%',
    height: '1px',
    marginTop: '-0.5px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        '&::before': {
          marginRight: sizing.contentSides,
          marginLeft: sizing.contentSides,
        },
      },
      medium: {
        '&::before': {
          marginRight: sizing.sides,
          marginLeft: sizing.sides,
        },
      },
    },
  },
});

const ShellHeaderVRule = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '0',
  height: '100%',

  '&:before': {
    content: '',
    position: 'absolute',
    display: 'block',
    width: '$1',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        '&:before': {
          '@notDesktop': {
            height: '$12',
          },

          '@desktop': {
            height: '$8',
          },
        },
      },
      medium: {
        '&:before': {
          '@notDesktop': {
            height: '$16',
          },

          '@desktop': {
            height: '$12',
          },
        },
      },
    },
  },
});

const ShellHeaderIcon = styled(DynamicIcon, {
  display: 'flex',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },
      medium: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

const ShellHeaderHeading = styled(Text, {
  maxWidth: '100%',
  truncate: true,
  color: colors.headingNeutralLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  variants: {
    size: {
      'x-small': {
        '@notDesktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },

        '@desktop': {
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
      medium: {
        '@notDesktop': {
          fontSize: '$16',
          lineHeight: '$24',
        },

        '@desktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
    },
  },
});

const ShellHeaderLockup = styled('div', {
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',

  variants: {
    size: {
      'x-small': {
        gap: '$4',
      },
      medium: {
        gap: '$6',
      },
    },
  },
});

const ShellHeaderStartScroll = styled('div', HorizontalScrollShadow, {
  minWidth: 0,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    hasEnd: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        '@notDesktop': {
          gap: '$16',
          padding: `$8 ${sizing.contentSides}`,
        },

        '@desktop': {
          gap: '$12',
          minHeight: '$32',
          padding: `$4 ${sizing.contentSides}`,
        },
      },
      medium: {
        padding: sizing.sidesOnly,

        '@notDesktop': {
          gap: '$20',
          padding: `$12 ${sizing.sides}`,
        },

        '@desktop': {
          gap: '$16',
          padding: `$8 ${sizing.sides}`,
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasEnd: true,
      css: {
        paddingRight: '$6',
      },
    },
    {
      size: 'medium',
      hasEnd: true,
      css: {
        paddingRight: '$10',
      },
    },
  ],
});

const ShellHeaderStart = styled('div', {
  minWidth: 0,
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
});

const ShellHeaderEnd = styled('div', {
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',

  variants: {
    hasStart: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        padding: sizing.contentSidesOnly,

        '@notDesktop': {
          gap: '$6',
        },

        '@desktop': {
          gap: '$4',
        },
      },
      medium: {
        padding: sizing.sidesOnly,

        '@notDesktop': {
          gap: '$8',
        },

        '@desktop': {
          gap: '$6',
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasStart: true,
      css: {
        paddingLeft: '$6',
      },
    },
    {
      size: 'medium',
      hasStart: true,
      css: {
        paddingLeft: '$10',
      },
    },
  ],
});

const ShellHeaderEnds = styled('div', {
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  variants: {
    size: {
      'x-small': {
        '@notDesktop': {
          minHeight: '$40',
        },

        '@desktop': {
          minHeight: '$32',
        },
      },
      medium: {
        '@notDesktop': {
          minHeight: '$52',
        },

        '@desktop': {
          minHeight: '$44',
        },
      },
    },
  },
});

const ShellHeaderToolbar = styled(ShellHeaderEnds);

const ShellHeaderBannerIcon = styled(DynamicIcon);

const ShellHeaderBannerHeading = styled(Large, {
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
});

const ShellHeaderBannerLockup = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$12',

  variants: {
    hasNavigation: {
      true: {
        padding: '$8 0 0',
      },
      false: {
        padding: '$8 0',
      },
    },
  },
});

const ShellHeaderBanner = styled(ShellHeaderEnds);
const ShellHeaderBannerStart = styled(ShellHeaderStart);
const ShellHeaderBannerStartScroll = styled(ShellHeaderStartScroll, {
  variants: {
    hasEnd: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        '@notDesktop': {
          gap: '$16',
          padding: `$8 ${sizing.contentSides}`,
        },

        '@desktop': {
          gap: '$12',
          minHeight: '$32',
          padding: `$4 ${sizing.contentSides}`,
        },
      },
      medium: {
        '@notDesktop': {
          gap: '$20',
          padding: `$10 ${sizing.sides}`,
        },

        '@desktop': {
          gap: '$16',
          padding: `$8 ${sizing.sides}`,
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasEnd: true,
      css: {
        paddingRight: '$6',
      },
    },
    {
      size: 'medium',
      hasEnd: true,
      css: {
        paddingRight: '$10',
      },
    },
  ],
});
const ShellHeaderBannerEnd = styled(ShellHeaderEnd);

const ShellHeaderNavigation = styled(ShellHeaderEnds);
const ShellHeaderNavigationStart = styled(ShellHeaderStart);
const ShellHeaderNavigationStartScroll = styled(ShellHeaderStartScroll, {
  variants: {
    hasEnd: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        '@notDesktop': {
          gap: '$16',
          padding: `$6 ${sizing.contentSides}`,
        },

        '@desktop': {
          gap: '$12',
          minHeight: '$32',
          padding: `$4 ${sizing.contentSides}`,
        },
      },
      medium: {
        '@notDesktop': {
          gap: '$20',
          padding: `$10 ${sizing.sides}`,
        },

        '@desktop': {
          gap: '$16',
          padding: `$8 ${sizing.sides}`,
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasEnd: true,
      css: {
        paddingRight: '$6',
      },
    },
    {
      size: 'medium',
      hasEnd: true,
      css: {
        paddingRight: '$10',
      },
    },
  ],
});
const ShellHeaderNavigationEnd = styled(ShellHeaderEnd);

const ShellHeaderTabsParent = styled(Tabs);

const ShellHeaderTabsChildren = styled(Tabs, {
  position: 'relative',

  '&:not(:first-child)::before': {
    content: '',
    position: 'absolute',
    top: '50%',
    left: '-9px',
    display: 'flex',
    width: '2px',
    minWidth: '2px',
    height: '$16',
    minHeight: '$16',
    marginTop: '-8px',
    backgroundColor: colors.strokeNeutralLight,
    borderRadius: '99em',

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

const ShellHeaderViews = styled(ShellHeaderEnds);

const ShellHeaderContainer = styled('div', {
  gridArea: 'shell-header',
  position: 'relative',
  zIndex: 2,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',

  variants: {
    size: {
      'x-small': {},
      medium: {},
    },
  },
});

const ShellToolbarContainer = styled('div', {
  gridArea: 'shell-toolbar',
  position: 'relative',
  zIndex: 2,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.bgNeutralLight,
  strokeBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
    strokeBottom: colors.strokeNeutralDark,
  },

  variants: {
    size: {
      'x-small': {},
      medium: {},
    },
  },
});

export type ShellHeaderProps = {
  /**
   * Provide any actions for your pane.
   */
  actions?: React.ReactNode;
  /**
   * Inline a back button, pass in the onClick event on this prop.
   */
  back?: () => void;
  /**
   * Show relevant badges alongside the `heading`.
   */
  badges?: React.ReactNode;
  /**
   * Provide a label for the close button.
   */
  closeButtonAriaLabel?: string;
  /**
   * Provide any actions for the displayed content.
   */
  contentActions?: React.ReactNode;
  /**
   * Show the total count of items.
   */
  count?: React.ReactNode;
  /**
   * Provide a list of breadcrumbs.
   */
  crumbs?: BreadcrumbProps[];
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Inline a home button, pass in the onClick event on this prop.
   */
  home?: BreadcrumbsPropHome;
  /**
   * Provide an icon for your pane.
   */
  icon?: DynamicIconPropIcon;
  /**
   * Provide a handler and opt into adding a close icon to the right.
   */
  onClose?: React.ReactEventHandler;
  /**
   * Provide any subtabs in addition to tabs.
   */
  subtabs?: React.ReactNode;
  /**
   * Allows for a select interface to switch between objects.
   */
  switcher?: React.ReactNode;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: React.ReactNode;
  /**
   * Provide the views and filters utilizing the `views` prop.
   */
  views?: React.ReactNode;
  /**
   * Provide a layout mode for your shell.
   * Note: Automatically provided from the `Shell` component, you likely don't need to provide this.
   */
  layoutMode?: ShellPropLayoutMode;
  /**
   * Provide a size for your shell.
   * Note: Automatically provided from the `Shell` component, you likely don't need to provide this.
   */
  size?: ShellPropSize;
};

export function ShellHeader({
  actions,
  back,
  badges,
  closeButtonAriaLabel = 'Close',
  contentActions,
  count,
  crumbs,
  heading,
  home,
  icon,
  layoutMode,
  onClose,
  size,
  subtabs,
  switcher,
  tabs,
  views,
  ...remaining
}: ShellHeaderProps) {
  const shellLayoutMode = useShellLayoutMode(layoutMode, 'compact');
  const isDetailed = shellLayoutMode === 'detailed';
  const shellSize = useShellSize(size, 'medium');
  const { breakpoint } = useViewport();

  const toolbarRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getToolbarShadow,
    onScrollHandler: onToolbarScrollHandler,
    handleTargetChange: handleToolbarTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeToolbar = useCallback(() => {
    if (toolbarRef.current) {
      handleToolbarTargetChange(toolbarRef.current);
    }
  }, [handleToolbarTargetChange]);
  useEffect(() => {
    handleResizeToolbar();
    window.addEventListener('resize', handleResizeToolbar);
    return () => {
      window.removeEventListener('resize', handleResizeToolbar);
    };
  }, [handleResizeToolbar]);

  const bannerRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getBannerShadow,
    onScrollHandler: onBannerScrollHandler,
    handleTargetChange: handleBannerTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeBanner = useCallback(() => {
    if (bannerRef.current) {
      handleBannerTargetChange(bannerRef.current);
    }
  }, [handleBannerTargetChange]);
  useEffect(() => {
    handleResizeBanner();
    window.addEventListener('resize', handleResizeBanner);
    return () => {
      window.removeEventListener('resize', handleResizeBanner);
    };
  }, [handleResizeBanner]);

  const navigationRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getNavigationShadow,
    onScrollHandler: onNavigationScrollHandler,
    handleTargetChange: handleNavigationTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeNavigation = useCallback(() => {
    if (navigationRef.current) {
      handleNavigationTargetChange(navigationRef.current);
    }
  }, [handleNavigationTargetChange]);
  useEffect(() => {
    handleResizeNavigation();
    window.addEventListener('resize', handleResizeNavigation);
    return () => {
      window.removeEventListener('resize', handleResizeNavigation);
    };
  }, [handleResizeNavigation]);

  const viewsRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getViewsShadow,
    onScrollHandler: onViewsScrollHandler,
    handleTargetChange: handleViewsTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeViews = useCallback(() => {
    if (viewsRef.current) {
      handleViewsTargetChange(viewsRef.current);
    }
  }, [handleViewsTargetChange]);
  useEffect(() => {
    handleResizeViews();
    window.addEventListener('resize', handleResizeViews);
    return () => {
      window.removeEventListener('resize', handleResizeViews);
    };
  }, [handleResizeViews]);

  const hasToolbarLockup = Boolean(icon || heading || count != null || switcher);
  const hasToolbarStart = Boolean(back || home || crumbs || (!isDetailed && hasToolbarLockup));
  const hasToolbarEnd = !isDetailed && Boolean(actions || onClose);
  const hasToolbar = Boolean(hasToolbarStart || hasToolbarEnd);

  const hasNavigationStart = Boolean(tabs || subtabs);
  const hasNavigationEnd = Boolean(contentActions);
  const hasNavigation = Boolean(hasNavigationStart || hasNavigationEnd);

  const hasBannerStart = Boolean(icon || heading || count != null || badges);
  const hasBannerEnd = Boolean(actions);
  const hasBanner = isDetailed && Boolean(hasBannerStart || hasBannerEnd);

  const hasViews = Boolean(views);

  return (
    (hasToolbar || hasBanner || hasNavigation) && (
      <>
        {isDetailed && hasToolbar && (
          <ShellToolbarContainer size={shellSize}>
            <ShellHeaderStart>
              <ShellHeaderStartScroll
                size={shellSize}
                hasEnd={hasToolbarEnd}
                onScroll={onToolbarScrollHandler}
                ref={toolbarRef}
                {...getToolbarShadow}
              >
                {hasToolbarStart && (
                  <>
                    {(back || (isDetailed && crumbs)) && (
                      <Breadcrumbs
                        back={back}
                        home={home}
                        crumbs={
                          isDetailed
                            ? [
                                ...(crumbs || []),
                                ...(switcher
                                  ? [
                                      {
                                        type: 'field' as BreadcrumbPropType,
                                        field: {
                                          field: switcher,
                                        },
                                      },
                                    ]
                                  : []),
                              ]
                            : undefined
                        }
                      />
                    )}
                    {!isDetailed && (back || crumbs) && hasToolbarLockup && (
                      <ShellHeaderVRule size={shellSize} />
                    )}
                    {hasToolbarLockup && (
                      <ShellHeaderLockup size={shellSize}>
                        {!isDetailed && icon && <ShellHeaderIcon hasSize={shellSize} icon={icon} />}
                        {!isDetailed && !switcher && heading && (
                          <ShellHeaderHeading size={shellSize}>{heading}</ShellHeaderHeading>
                        )}
                        {!isDetailed && count != null && (
                          <Badge
                            ends="card"
                            size={shellSize === 'x-small' ? 'x-small' : 'small'}
                            variant="neutral"
                          >
                            {count}
                          </Badge>
                        )}
                        {!isDetailed && switcher}
                      </ShellHeaderLockup>
                    )}
                    {hasToolbarLockup && !isDetailed && badges && (
                      <ShellHeaderVRule size={shellSize} />
                    )}
                    {!isDetailed && badges && (
                      <BadgeGroup
                        relation="separate"
                        size={
                          breakpoint === 'desktop' || shellSize === 'x-small' ? 'small' : 'medium'
                        }
                      >
                        {badges}
                      </BadgeGroup>
                    )}
                  </>
                )}
              </ShellHeaderStartScroll>
            </ShellHeaderStart>
            {hasToolbarEnd && (
              <ShellHeaderEnd size={shellSize} hasStart={hasToolbarStart}>
                {actions && (
                  <ControlGroup
                    size={shellSize === 'x-small' ? 'x-small' : 'small'}
                    relation="separate"
                  >
                    {actions}
                  </ControlGroup>
                )}
                {onClose && <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel} />}
              </ShellHeaderEnd>
            )}
          </ShellToolbarContainer>
        )}
        <ShellHeaderContainer size={shellSize} {...remaining}>
          {!isDetailed && hasToolbar && (
            <ShellHeaderToolbar size={shellSize}>
              <ShellHeaderStart>
                <ShellHeaderStartScroll
                  size={shellSize}
                  hasEnd={hasToolbarEnd}
                  onScroll={onToolbarScrollHandler}
                  ref={toolbarRef}
                  {...getToolbarShadow}
                >
                  {hasToolbarStart && (
                    <>
                      {(back || (isDetailed && crumbs)) && (
                        <Breadcrumbs
                          back={back}
                          home={home}
                          crumbs={
                            isDetailed
                              ? [
                                  ...(crumbs || []),
                                  ...(switcher
                                    ? [
                                        {
                                          type: 'field' as BreadcrumbPropType,
                                          field: {
                                            field: switcher,
                                          },
                                        },
                                      ]
                                    : []),
                                ]
                              : undefined
                          }
                        />
                      )}
                      {!isDetailed && (back || crumbs) && hasToolbarLockup && (
                        <ShellHeaderVRule size={shellSize} />
                      )}
                      {hasToolbarLockup && (
                        <ShellHeaderLockup size={shellSize}>
                          {!isDetailed && icon && (
                            <ShellHeaderIcon hasSize={shellSize} icon={icon} />
                          )}
                          {!isDetailed && !switcher && heading && (
                            <ShellHeaderHeading size={shellSize}>{heading}</ShellHeaderHeading>
                          )}
                          {!isDetailed && count != null && (
                            <Badge
                              ends="card"
                              size={shellSize === 'x-small' ? 'x-small' : 'small'}
                              variant="neutral"
                            >
                              {count}
                            </Badge>
                          )}
                          {!isDetailed && switcher}
                        </ShellHeaderLockup>
                      )}
                      {hasToolbarLockup && !isDetailed && badges && (
                        <ShellHeaderVRule size={shellSize} />
                      )}
                      {!isDetailed && badges && (
                        <BadgeGroup
                          relation="separate"
                          size={
                            breakpoint === 'desktop' || shellSize === 'x-small' ? 'small' : 'medium'
                          }
                        >
                          {badges}
                        </BadgeGroup>
                      )}
                    </>
                  )}
                </ShellHeaderStartScroll>
              </ShellHeaderStart>
              {hasToolbarEnd && (
                <ShellHeaderEnd size={shellSize} hasStart={hasToolbarStart}>
                  {actions && (
                    <ControlGroup
                      size={shellSize === 'x-small' ? 'x-small' : 'small'}
                      relation="separate"
                    >
                      {actions}
                    </ControlGroup>
                  )}
                  {onClose && <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel} />}
                </ShellHeaderEnd>
              )}
            </ShellHeaderToolbar>
          )}
          {!hasBanner && hasToolbar && hasNavigation && <ShellHRule size={shellSize} />}
          {hasBanner && (
            <ShellHeaderBanner>
              {hasBannerStart && (
                <ShellHeaderBannerStart>
                  <ShellHeaderBannerStartScroll
                    size={shellSize}
                    hasEnd={!hasNavigation && !!contentActions}
                    onScroll={onBannerScrollHandler}
                    ref={bannerRef}
                    {...getBannerShadow}
                  >
                    <ShellHeaderBannerLockup hasNavigation={hasNavigation}>
                      {icon && <ShellHeaderBannerIcon icon={icon} size={space(28)} />}
                      <ShellHeaderBannerHeading>{heading}</ShellHeaderBannerHeading>
                      {count != null && (
                        <Badge ends="card" size="small" variant="neutral">
                          {count}
                        </Badge>
                      )}
                      {badges && (
                        <BadgeGroup
                          relation="separate"
                          size={
                            breakpoint === 'desktop' || shellSize === 'x-small' ? 'small' : 'medium'
                          }
                        >
                          {badges}
                        </BadgeGroup>
                      )}
                    </ShellHeaderBannerLockup>
                  </ShellHeaderBannerStartScroll>
                </ShellHeaderBannerStart>
              )}
              {hasBannerEnd && (
                <ShellHeaderBannerEnd size={shellSize} hasStart={hasToolbarStart}>
                  {actions && (
                    <ControlGroup
                      size={shellSize === 'x-small' ? 'x-small' : 'small'}
                      relation="separate"
                    >
                      {actions}
                    </ControlGroup>
                  )}
                  {onClose && <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel} />}
                </ShellHeaderBannerEnd>
              )}
            </ShellHeaderBanner>
          )}
          {hasNavigation && (
            <ShellHeaderNavigation>
              {hasNavigationStart && (
                <ShellHeaderNavigationStart>
                  <ShellHeaderNavigationStartScroll
                    size={shellSize}
                    hasEnd={hasNavigationEnd}
                    onScroll={onNavigationScrollHandler}
                    ref={navigationRef}
                    {...getNavigationShadow}
                  >
                    {tabs && <ShellHeaderTabsParent>{tabs}</ShellHeaderTabsParent>}
                    {subtabs && <ShellHeaderTabsChildren>{subtabs}</ShellHeaderTabsChildren>}
                  </ShellHeaderNavigationStartScroll>
                </ShellHeaderNavigationStart>
              )}
              {hasNavigationEnd && (
                <ShellHeaderNavigationEnd size={shellSize} hasStart={hasNavigationStart}>
                  {contentActions && (
                    <ControlGroup
                      size={shellSize === 'x-small' ? 'x-small' : 'small'}
                      relation="separate"
                    >
                      {contentActions}
                    </ControlGroup>
                  )}
                </ShellHeaderNavigationEnd>
              )}
            </ShellHeaderNavigation>
          )}
          {(hasNavigation || hasBanner) && hasViews && <ShellHRule size={shellSize} />}
          {hasViews && (
            <ShellHeaderViews>
              <ShellHeaderStart>
                <ShellHeaderStartScroll
                  size={shellSize}
                  onScroll={onViewsScrollHandler}
                  ref={viewsRef}
                  {...getViewsShadow}
                >
                  {views}
                </ShellHeaderStartScroll>
              </ShellHeaderStart>
            </ShellHeaderViews>
          )}
          <ShellHRule size={shellSize} />
        </ShellHeaderContainer>
      </>
    )
  );
}

const ShellContentSpacing = styled(VStack, {
  width: '100%',
  height: '100%',

  variants: {
    gutter: {
      all: {},
      vertical: {},
      horizontal: {},
      top: {},
      right: {},
      bottom: {},
      left: {},
      none: {
        padding: 0,
      },
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },

  compoundVariants: [
    {
      size: 'x-small',
      gutter: 'all',
      css: {
        padding: sizing.contentSquish,
      },
    },
    {
      size: 'x-small',
      gutter: 'vertical',
      css: {
        padding: sizing.contentEndsOnly,
      },
    },
    {
      size: 'x-small',
      gutter: 'horizontal',
      css: {
        padding: sizing.contentSidesOnly,
      },
    },
    {
      size: 'x-small',
      gutter: 'top',
      css: {
        paddingTop: sizing.contentEnds,
      },
    },
    {
      size: 'x-small',
      gutter: 'right',
      css: {
        paddingRight: sizing.contentSides,
      },
    },
    {
      size: 'x-small',
      gutter: 'bottom',
      css: {
        paddingBottom: sizing.contentEnds,
      },
    },
    {
      size: 'x-small',
      gutter: 'left',
      css: {
        paddingLeft: sizing.contentSides,
      },
    },
    {
      size: 'medium',
      gutter: 'all',
      css: {
        padding: sizing.squish,
      },
    },
    {
      size: 'medium',
      gutter: 'vertical',
      css: {
        padding: sizing.endsOnly,
      },
    },
    {
      size: 'medium',
      gutter: 'horizontal',
      css: {
        padding: sizing.sidesOnly,
      },
    },
    {
      size: 'medium',
      gutter: 'top',
      css: {
        paddingTop: sizing.ends,
      },
    },
    {
      size: 'medium',
      gutter: 'right',
      css: {
        paddingRight: sizing.sides,
      },
    },
    {
      size: 'medium',
      gutter: 'bottom',
      css: {
        paddingBottom: sizing.ends,
      },
    },
    {
      size: 'medium',
      gutter: 'left',
      css: {
        paddingLeft: sizing.sides,
      },
    },
  ],
});

const ShellContentScroll = styled(VerticalScrollShadow, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100%',
});

const ShellContentContainer = styled('div', {
  gridArea: 'shell-content',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minWidth: 0,
  overflow: 'hidden',

  '&::before': {
    position: 'absolute',
    top: '-0.5px',
    left: sizing.sides,
    right: sizing.sides,
    content: '',
    display: 'block',
    height: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    background: {
      checkered: {
        background: backgrounds.checkeredLight,
        backgroundColor: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: backgrounds.checkeredDark,
          backgroundColor: colors.bgApplicationDark,
        },
      },
      dotted: {
        background: backgrounds.dottedLight,
        backgroundColor: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: backgrounds.dottedDark,
          backgroundColor: colors.bgApplicationDark,
        },
      },
      default: {},
    },
  },
});

export type ShellContentProps = {
  /**
   * Set whether the background should be checkered or not.
   */
  background?: 'default' | 'checkered' | 'dotted';
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Set the size of the shell.
   */
  size?: ShellPropSize;
  /**
   * Set the spacing between the children.
   */
  spacing?: StackSpacing;
} & ComponentProps<typeof ShellContentContainer>;

export const ShellContent = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      children,
      background,
      gutter = 'all',
      size,
      spacing = space(16),
      ...remaining
    }: ShellContentProps,
    ref: PolymorphicRef<Tag>,
  ) => {
    const shellSize = useShellSize(size);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { getBoxShadow, onScrollHandler, handleTargetChange } = useVerticalShadowOnScroll('both');

    const handleResize = useCallback(() => {
      if (scrollRef.current) {
        handleTargetChange(scrollRef.current);
      }
    }, [handleTargetChange]);

    useEffect(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [handleResize]);

    const scrollProps = !ref
      ? {
          ref: scrollRef,
          onScroll: onScrollHandler,
          ...getBoxShadow,
        }
      : {
          ref,
        };

    return (
      <ShellContentContainer background={background} {...remaining}>
        <ShellContentScroll {...scrollProps}>
          <ShellContentSpacing size={shellSize} gutter={gutter} spacing={spacing}>
            {children}
          </ShellContentSpacing>
        </ShellContentScroll>
      </ShellContentContainer>
    );
  },
);

const ShellFooterStart = styled(ControlGroup);

const ShellFooterEnd = styled(ControlGroup);

const ShellFooterContainer = styled('div', {
  gridArea: 'shell-footer',
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$12',
  width: '100%',
  overflow: 'auto',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '-0.5px',
    right: sizing.sides,
    left: sizing.sides,
    height: '$1',
    background: colors.strokeApplicationLight,
    borderRadius: '$2',

    [darkThemeSelector]: {
      background: colors.strokeApplicationDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        padding: sizing.contentSidesOnly,

        '@notDesktop': {
          minHeight: '$40',
        },

        '@desktop': {
          minHeight: '$32',
        },
      },
      medium: {
        padding: sizing.sidesOnly,

        '@notDesktop': {
          minHeight: '$52',
        },

        '@desktop': {
          minHeight: '$44',
        },
      },
    },
  },
});

export type ShellFooterProps = {
  /**
   * The end content for the footer, usually used for actions.
   */
  end?: React.ReactNode;
  /**
   * The start content for the footer, usually used for additional context and helpers.
   */
  start?: React.ReactNode;
  /**
   * Set the size of the shell.
   */
  size?: ShellPropSize;
};

export function ShellFooter({ end, size, start, ...remaining }: ShellFooterProps) {
  const shellSize = useShellSize(size, 'medium');
  return (
    <ShellFooterContainer size={shellSize} {...remaining}>
      <ShellFooterStart relation="separate" size={shellSize}>
        {start}
      </ShellFooterStart>
      <ShellFooterEnd relation="separate" size={shellSize}>
        {end}
      </ShellFooterEnd>
    </ShellFooterContainer>
  );
}

export const ShellContainer = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"shell-toolbar" "shell-header" "shell-content" "shell-footer"',
  width: '100%',
  height: '100%',

  '& form': {
    // This allows for the use of a `<form>` inside of any `Shell` components
    // without affecting the flexbox of the children.
    display: 'contents',
  },

  variants: {
    contentMode: {
      fit: {
        gridTemplateRows:
          'fit-content(100%) fit-content(100%) minmax(0, min-content) fit-content(100%)',
      },
      stretch: {
        gridTemplateRows: 'fit-content(100%) fit-content(100%) 1fr fit-content(100%)',
      },
    },
  },
});

export type ShellPropContentMode = 'fit' | 'stretch';

export type ShellProps = {
  children: React.ReactNode;
  contentMode?: ShellPropContentMode;
  layoutMode?: ShellPropLayoutMode;
  size?: ShellPropSize;
};

export function Shell({
  children,
  contentMode = 'stretch',
  layoutMode = 'compact',
  size = 'medium',
  ...remaining
}: ShellProps) {
  return (
    <ShellSizeProvider value={size}>
      <ShellLayoutModeProvider value={layoutMode}>
        <ShellContainer contentMode={contentMode} {...remaining}>
          {children}
        </ShellContainer>
      </ShellLayoutModeProvider>
    </ShellSizeProvider>
  );
}
